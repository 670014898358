export const GET_PAGES_REQUEST = "GET_PAGES_REQUEST";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";

export const SHOW_PAGE_REQUEST = "SHOW_PAGE_REQUEST";
export const SHOW_PAGE_SUCCESS = "SHOW_PAGE_SUCCESS";

export const POST_PAGE_REQUEST = "POST_PAGE_REQUEST";
export const POST_PAGE_SUCCESS = "POST_PAGE_SUCCESS";

export const PUT_PAGE_REQUEST = "PUT_PAGE_REQUEST";
export const PUT_PAGE_SUCCESS = "PUT_PAGE_SUCCESS";

export const DELETE_PAGE_REQUEST = "DELETE_PAGE_REQUEST";
export const DELETE_PAGE_SUCCESS = "DELETE_PAGE_SUCCESS";
