import * as ACTIONS from "@iso/redux/trainings/actionTypes";

const initialState = {
  categories: {
    list: [],
    total: 0,
  },
  trainings: {
    list: [],
    total: 0,
  },
};

function trainingsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_TRAININGS_SUCCESS: {
      return {
        ...state,
        ...{ trainings: payload },
      };
    }
    case ACTIONS.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        ...{ categories: payload },
      };
    }

    default:
      return state;
  }
}

export default trainingsReducer;
