export const GET_QUESTIONS_REQUEST = "GET_QUESTIONS_REQUEST";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";

export const GET_QUESTION_REQUEST = "GET_QUESTION_REQUEST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";

export const POST_QUESTION_REQUEST = "POST_QUESTION_REQUEST";
export const POST_QUESTION_SUCCESS = "POST_QUESTION_SUCCESS";

export const DELETE_QUESTION_REQUEST = "DELETE_QUESTION_REQUEST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";

export const GET_ANSWERS_REQUEST = "GET_ANSWERS_REQUEST";
export const GET_ANSWERS_SUCCESS = "GET_ANSWERS_SUCCESS";

export const SHOW_ANSWER_REQUEST = "SHOW_ANSWER_REQUEST";
export const SHOW_ANSWER_SUCCESS = "SHOW_ANSWER_SUCCESS";

export const POST_ANSWER_REQUEST = "POST_ANSWER_REQUEST";
export const POST_ANSWER_SUCCESS = "POST_ANSWER_SUCCESS";

export const PUT_ANSWER_REQUEST = "PUT_ANSWER_REQUEST";
export const PUT_ANSWER_SUCCESS = "PUT_ANSWER_SUCCESS";

export const DELETE_ANSWER_REQUEST = "DELETE_ANSWER_REQUEST";
export const DELETE_ANSWER_SUCCESS = "DELETE_ANSWER_SUCCESS";
