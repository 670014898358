import * as ACTIONS from "@iso/redux/venfaq/actionTypes";

const initialState = {
  venfaqs: {
    list: [],
    total: 0,
  },
};

function venfaqsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_VENFAQS_SUCCESS: {
      return {
        ...state,
        ...{ venfaqs: payload },
      };
    }

    default:
      return state;
  }
}

export default venfaqsReducer;
