export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";

export const GET_TRAININGS_REQUEST = "GET_TRAININGS_REQUEST";
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS";

export const SHOW_TRAINING_REQUEST = "SHOW_TRAINING_REQUEST";
export const SHOW_TRAINING_SUCCESS = "SHOW_TRAINING_SUCCESS";

export const POST_TRAINING_REQUEST = "POST_TRAINING_REQUEST";
export const POST_TRAINING_SUCCESS = "POST_TRAINING_SUCCESS";

export const PUT_TRAINING_REQUEST = "PUT_TRAINING_REQUEST";
export const PUT_TRAINING_SUCCESS = "PUT_TRAINING_SUCCESS";

export const DELETE_TRAINING_REQUEST = "DELETE_TRAINING_REQUEST";
export const DELETE_TRAINING_SUCCESS = "DELETE_TRAINING_SUCCESS";

export const GET_TRAININGS_USERS_REQUEST = "GET_TRAININGS_USERS_REQUEST";
export const GET_TRAININGS_USERS_SUCCESS = "GET_TRAININGS_USERS_SUCCESS";
