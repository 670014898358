import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './Reducers';
import { logger } from 'redux-logger';

export function getStore(initialState) {
  let store;

  if (process.env.NODE_ENV === 'development') {
    store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunk, logger)));
  } else {
    store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(thunk)));
  }

  return store;
}
