export const GET_COMMENTS_REQUEST = "GET_COMMENTS_REQUEST";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";

export const SHOW_COMMENT_REQUEST = "SHOW_COMMENT_REQUEST";
export const SHOW_COMMENT_SUCCESS = "SHOW_COMMENT_SUCCESS";

export const PUT_COMMENT_REQUEST = "PUT_COMMENT_REQUEST";
export const PUT_COMMENT_SUCCESS = "PUT_COMMENT_SUCCESS";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
