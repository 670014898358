import * as ACTIONS from "@iso/redux/materials/actionTypes";

const initialState = {
  materials: {
    list: [],
    total: 0,
  },
};

function materialsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_MATERIALS_SUCCESS: {
      return {
        ...state,
        ...{ materials: payload },
      };
    }

    default:
      return state;
  }
}

export default materialsReducer;
