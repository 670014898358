export const GET_USER_SUBSCRIPTIONS_REQUEST = "GET_USER_SUBSCRIPTIONS_REQUEST";
export const GET_USER_SUBSCRIPTIONS_SUCCESS = "GET_USER_SUBSCRIPTIONS_SUCCESS";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";

export const SHOW_SUBSCRIPTION_REQUEST = "SHOW_SUBSCRIPTION_REQUEST";
export const SHOW_SUBSCRIPTION_SUCCESS = "SHOW_SUBSCRIPTION_SUCCESS";

export const PUT_SUBSCRIPTION_REQUEST = "PUT_SUBSCRIPTION_REQUEST";
export const PUT_SUBSCRIPTION_SUCCESS = "PUT_SUBSCRIPTION_SUCCESS";

export const PATCH_SUBSCRIPTION_REQUEST = "PATCH_SUBSCRIPTION_REQUEST";
export const PATCH_SUBSCRIPTION_SUCCESS = "PATCH_SUBSCRIPTION_SUCCESS";

export const DOWNLOAD_SUBSCRIPTIONS_EXCEL = "DOWNLOAD_SUBSCRIPTIONS_EXCEL";
export const DOWNLOAD_SUBSCRIPTIONS_EXCEL_SUCCESS = "DOWNLOAD_SUBSCRIPTIONS_EXCEL_SUCCESS";
