import * as ACTIONS from "@iso/redux/vensubscriptions/actionTypes";

const initialState = {
  vensubscriptions: {
    list: [],
    total: 0,
  },
};

function vensubscriptionsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_VENSUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        ...{ vensubscriptions: payload },
      };
    }

    default:
      return state;
  }
}

export default vensubscriptionsReducer;
