import * as ACTIONS from "@iso/redux/venquestions/actionTypes";

const initialState = {
  venquestions: {
    list: [],
    total: 0,
  },
};

function venquestionsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_VENQUESTIONS_SUCCESS: {
      return {
        ...state,
        ...{ venquestions: payload },
      };
    }

    default:
      return state;
  }
}

export default venquestionsReducer;
