export const GET_VENFAQS_REQUEST = "GET_VENFAQS_REQUEST";
export const GET_VENFAQS_SUCCESS = "GET_VENFAQS_SUCCESS";

export const SHOW_VENFAQ_REQUEST = "SHOW_VENFAQ_REQUEST";
export const SHOW_VENFAQ_SUCCESS = "SHOW_VENFAQ_SUCCESS";

export const POST_VENFAQ_REQUEST = "POST_VENFAQ_REQUEST";
export const POST_VENFAQ_SUCCESS = "POST_VENFAQ_SUCCESS";

export const PUT_VENFAQ_REQUEST = "PUT_VENFAQ_REQUEST";
export const PUT_VENFAQ_SUCCESS = "PUT_VENFAQ_SUCCESS";

export const DELETE_VENFAQ_REQUEST = "DELETE_VENFAQ_REQUEST";
export const DELETE_VENFAQ_SUCCESS = "DELETE_VENFAQ_SUCCESS";
