export const GET_BANNERS_POSITIONS_REQUEST = "GET_BANNERS_POSITION_REQUEST";
export const GET_BANNERS_POSITIONS_SUCCESS = "GET_BANNERS_POSITIONS_SUCCESS";

export const GET_BANNERS_REQUEST = "GET_BANNERS_REQUEST";
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";

export const SHOW_BANNER_REQUEST = "SHOW_BANNER_REQUEST";
export const SHOW_BANNER_SUCCESS = "SHOW_BANNER_SUCCESS";

export const POST_BANNER_REQUEST = "POST_BANNER_REQUEST";
export const POST_BANNER_SUCCESS = "POST_BANNER_SUCCESS";

export const PUT_BANNER_REQUEST = "PUT_BANNER_REQUEST";
export const PUT_BANNER_SUCCESS = "PUT_BANNER_SUCCESS";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
