export const GET_LANDINGS_REQUEST = "GET_LANDINGS_REQUEST";
export const GET_LANDINGS_SUCCESS = "GET_LANDINGS_SUCCESS";

export const SHOW_LANDING_REQUEST = "SHOW_LANDING_REQUEST";
export const SHOW_LANDING_SUCCESS = "SHOW_LANDING_SUCCESS";

export const POST_LANDING_REQUEST = "POST_LANDING_REQUEST";
export const POST_LANDING_SUCCESS = "POST_LANDING_SUCCESS";

export const PUT_LANDING_REQUEST = "PUT_LANDING_REQUEST";
export const PUT_LANDING_SUCCESS = "PUT_LANDING_SUCCESS";

export const DELETE_LANDING_REQUEST = "DELETE_LANDING_REQUEST";
export const DELETE_LANDING_SUCCESS = "DELETE_LANDING_SUCCESS";

export const GET_LANDINGS_SECTION_TYPES_REQUEST = "GET_LANDINGS_SECTION_TYPES_REQUEST";
export const GET_LANDINGS_SECTION_TYPES_SUCCESS = "GET_LANDINGS_SECTION_TYPES_SUCCESS";

export const GET_LANDINGS_SECTIONS_REQUEST = "GET_LANDINGS_SECTIONS_REQUEST";
export const GET_LANDINGS_SECTIONS_SUCCESS = "GET_LANDINGS_SECTIONS_SUCCESS";

export const GET_LANDING_SECTIONS_REQUEST = "GET_LANDING_SECTIONS_REQUEST";
export const GET_LANDING_SECTIONS_SUCCESS = "GET_LANDING_SECTIONS_SUCCESS";

export const PUT_LANDING_SECTION_REQUEST = "PUT_LANDING_SECTION_REQUEST";
export const PUT_LANDING_SECTION_SUCCESS = "PUT_LANDING_SECTION_SUCCESS";

export const DELETE_LANDING_SECTION_REQUEST = "DELETE_LANDING_SECTION_REQUEST";
export const DELETE_LANDING_SECTION_SUCCESS = "DELETE_LANDING_SECTION_SUCCESS";

export const POST_LANDING_SECTION_REQUEST = "POST_LANDING_SECTION_REQUEST";
export const POST_LANDING_SECTION_SUCCESS = "POST_LANDING_SECTION_SUCCESS";
