import * as ACTIONS from "./actionTypes";

const initialState = {
  checklistNotes: {
    list: [],
    total: 0,
  },
};

function checklistNotesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CHECKLIST_NOTES_SUCCESS: {
      return {
        ...state,
        ...{ banners: payload },
      };
    }
    default:
      return state;
  }
}

export default checklistNotesReducer;
