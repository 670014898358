import * as ACTIONS from "@iso/redux/landings/actionTypes";

const initialState = {
  landings: {
    list: [],
    total: 0,
  },
  sectionTypes: {
    list: [],
    total: 0,
  },
  sectionItems: {
    list: [],
    total: 0,
  },
};

function landingsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_LANDINGS_SUCCESS: {
      return {
        ...state,
        ...{ landings: payload },
      };
    }
    case ACTIONS.GET_LANDINGS_SECTION_TYPES_SUCCESS: {
      return {
        ...state,
        ...{ sectionTypes: payload },
      };
    }
    case ACTIONS.GET_LANDINGS_SECTIONS_SUCCESS: {
      return {
        ...state,
        ...{ sectionItems: payload },
      };
    }
    default:
      return state;
  }
}

export default landingsReducer;
