export const GET_QUESTION_SUBSCRIPTIONS_REQUEST = "GET_QUESTION_SUBSCRIPTIONS_REQUEST";
export const GET_QUESTION_SUBSCRIPTIONS_SUCCESS = "GET_QUESTION_SUBSCRIPTIONS_SUCCESS";

export const SHOW_QUESTION_SUBSCRIPTION_REQUEST = "SHOW_QUESTION_SUBSCRIPTION_REQUEST";
export const SHOW_QUESTION_SUBSCRIPTION_SUCCESS = "SHOW_QUESTION_SUBSCRIPTION_SUCCESS";

export const PUT_QUESTION_SUBSCRIPTION_REQUEST = "PUT_QUESTION_SUBSCRIPTION_REQUEST";
export const PUT_QUESTION_SUBSCRIPTION_SUCCESS = "PUT_QUESTION_SUBSCRIPTION_SUCCESS";

export const DELETE_QUESTION_SUBSCRIPTION_REQUEST = "DELETE_QUESTION_SUBSCRIPTION_REQUEST";
export const DELETE_QUESTION_SUBSCRIPTION_SUCCESS = "DELETE_QUESTION_SUBSCRIPTION_SUCCESS";

export const GET_PUBLIC_QUESTION_SUBSCRIPTIONS_REQUEST = "GET_PUBLIC_QUESTION_SUBSCRIPTIONS_REQUEST";
export const GET_PUBLIC_QUESTION_SUBSCRIPTIONS_SUCCESS = "GET_PUBLIC_QUESTION_SUBSCRIPTIONS_SUCCESS";

export const DOWNLOAD_QUESTIONS_SUBSCRIPTIONS_EXCEL = "DOWNLOAD_QUESTIONS_SUBSCRIPTIONS_EXCEL";
export const DOWNLOAD_QUESTIONS_SUBSCRIPTIONS_EXCEL_SUCCESS = "DOWNLOAD_QUESTIONS_SUBSCRIPTIONS_EXCEL_SUCCESS";
