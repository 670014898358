import * as ACTIONS from "@iso/redux/pages/actionTypes";

const initialState = {
  categories: {
    list: [],
    total: 0,
  },
  pages: {
    list: [],
    total: 0,
  },
};

function pagesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_PAGES_SUCCESS: {
      return {
        ...state,
        ...{ pages: payload },
      };
    }

    default:
      return state;
  }
}

export default pagesReducer;
