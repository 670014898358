import * as ACTIONS from "@iso/redux/languages/actionTypes";

const initialState = {
  languages: [],
  langsMap: {},
};

function LanguageReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_LANGUAGES_SUCCESS: {
      return {
        ...state,
        ...{
          languages: payload.sort(function (a, b) {
            return a.id - b.id || a.name.localeCompare(b.name);
          }),
          langsMap: generateLangsMap(payload),
        },
      };
    }

    default:
      return state;
  }
}

const generateLangsMap = (langs) => {
  let response = {};
  langs.forEach((item) => {
    response = {
      ...response,
      [item.id]: item.slug,
    };
  });
  return response;
};

export default LanguageReducer;
