// LOGIN
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// LOGOUT
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

// USER REGISTER
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";

// JWT SECURITY TOKEN
export const GET_SECURITY_TOKEN_REQUEST = "GET_SECURITY_TOKEN_REQUEST";
export const GET_SECURITY_TOKEN_SUCCESS = "GET_SECURITY_TOKEN_SUCCESS";
export const GET_SECURITY_TOKEN_ERROR = "GET_SECURITY_TOKEN_ERROR";

// CREATE USER
export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";

// FORGOT PASSWORD
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// SET PASSWORD
export const PASSWORD_SET_REQUEST = "PASSWORD_SET_REQUEST";
export const PASSWORD_SET_SUCCESS = "PASSWORD_SET_SUCCESS";
export const PASSWORD_SET_ERROR = "PASSWORD_SET_ERROR";

// CHANGE PASSWORD
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

// GET USER
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

// UPDATE USER
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

// GET USER
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";

// DELETE USER
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

//DONLOAD USER EXCEL
export const DOWNLOAD_USERS_EXCEL = "DOWNLOAD_USERS_EXCEL";
export const DOWNLOAD_USERS_EXCEL_SUCCESS = "DOWNLOAD_USERS_EXCEL_SUCCESS";

//UNLOCK USER
export const UNLOCK_USER_REQUEST = "UNLOCK_USER_REQUEST";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
