import * as ACTIONS from "@iso/redux/questions/actionTypes";

const initialState = {
  questions: {
    list: [],
    total: 0,
  },
  answers: {
    list: [],
    total: 0,
    loading: true,
  },
  question: {},
};

function articlesReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_QUESTIONS_SUCCESS: {
      return {
        ...state,
        ...{ questions: payload },
      };
    }
    case ACTIONS.GET_ANSWERS_SUCCESS: {
      return {
        ...state,
        ...{ answers: payload },
      };
    }

    case ACTIONS.GET_QUESTION_SUCCESS: {
      return {
        ...state,
        ...{ question: payload },
      };
    }

    default:
      return state;
  }
}

export default articlesReducer;
