import * as ACTIONS from "@iso/redux/dashboard/actionTypes";

const initialState = {
  dashboardInfo: {},
};

function dashboardReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_DASHBOARD_INFO_SUCCESS: {
      return {
        ...state,
        ...{ dashboardInfo: payload },
      };
    }
    default:
      return state;
  }
}

export default dashboardReducer;
