export const GET_MATERIALS_REQUEST = "GET_MATERIALS_REQUEST";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";

export const SHOW_MATERIAL_REQUEST = "SHOW_MATERIAL_REQUEST";
export const SHOW_MATERIAL_SUCCESS = "SHOW_MATERIAL_SUCCESS";

export const POST_MATERIAL_REQUEST = "POST_MATERIAL_REQUEST";
export const POST_MATERIAL_SUCCESS = "POST_MATERIAL_SUCCESS";

export const PUT_MATERIAL_REQUEST = "PUT_MATERIAL_REQUEST";
export const PUT_MATERIAL_SUCCESS = "PUT_MATERIAL_SUCCESS";

export const DELETE_MATERIAL_REQUEST = "DELETE_MATERIAL_REQUEST";
export const DELETE_MATERIAL_SUCCESS = "DELETE_MATERIAL_SUCCESS";
