export const GET_VENQUESTIONS_REQUEST = "GET_VENQUESTIONS_REQUEST";
export const GET_VENQUESTIONS_SUCCESS = "GET_VENQUESTIONS_SUCCESS";

export const SHOW_VENQUESTION_REQUEST = "SHOW_VENQUESTION_REQUEST";
export const SHOW_VENQUESTION_SUCCESS = "SHOW_VENQUESTION_SUCCESS";

export const POST_VENQUESTION_REQUEST = "POST_VENQUESTION_REQUEST";
export const POST_VENQUESTION_SUCCESS = "POST_VENQUESTION_SUCCESS";

export const PUT_VENQUESTION_REQUEST = "PUT_VENQUESTION_REQUEST";
export const PUT_VENQUESTION_SUCCESS = "PUT_VENQUESTION_SUCCESS";

export const DELETE_VENQUESTION_REQUEST = "DELETE_VENQUESTION_REQUEST";
export const DELETE_VENQUESTION_SUCCESS = "DELETE_VENQUESTION_SUCCESS";
