export const GET_CHECKLIST_NOTES_REQUEST = "GET_CHECKLIST_NOTES_REQUEST";
export const GET_CHECKLIST_NOTES_SUCCESS = "GET_CHECKLIST_NOTES_SUCCESS";

export const SHOW_CHECKLIST_NOTES_REQUEST = "SHOW_CHECKLIST_NOTES_REQUEST";
export const SHOW_CHECKLIST_NOTES_SUCCESS = "SHOW_CHECKLIST_NOTES_SUCCESS";

export const POST_CHECKLIST_NOTES_REQUEST = "POST_CHECKLIST_NOTES_REQUEST";
export const POST_CHECKLIST_NOTES_SUCCESS = "POST_CHECKLIST_NOTES_SUCCESS";

export const PUT_CHECKLIST_NOTES_REQUEST = "PUT_CHECKLIST_NOTES_REQUEST";
export const PUT_CHECKLIST_NOTES_SUCCESS = "PUT_CHECKLIST_NOTES_SUCCESS";

export const DELETE_CHECKLIST_NOTES_REQUEST = "DELETE_CHECKLIST_NOTES_REQUEST";
export const DELETE_CHECKLIST_NOTES_SUCCESS = "DELETE_CHECKLIST_NOTES_SUCCESS";
